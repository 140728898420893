<div class="main-content mt-16">
  <div *ngIf="iconSet" class="px-4 pb-3 pt-px product-container">
    <div *ngIf="hasError; else showDetails">
      <div *ngIf="showNoAccessError; else showErrorMessage">
        <app-no-access-error></app-no-access-error>
      </div>
      <ng-template #showErrorMessage>
        <div class="row">
          <div class="col-12">
            <div class="mt-3 dashboard-error">
              <div>
                <h3 class="text-center">{{errorMessage}}</h3>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #showDetails>
      <ng-container data-cy="holdingDashboard">
        <div class="row" style="min-height: 42px">
          <div class="md:mt-4 product-container__bread-crumb grid md:col-12 md:flex md:pr-3.5">
            <div class="col-12 md:contents">
              <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                <a class="custom-breadcrumb__text" attr.data-cy="breadcrumb{{breadcrumb.type}}"
                  routerLinkActive="active" [routerLink]="breadcrumb.route"
                  [queryParams]="breadcrumb.queryParams"
                  (click)="breadcrumbClick(breadcrumb)" [title]="breadcrumb.name">
                  {{breadcrumb.name}}
                </a>
                <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
              </div>
            </div>
            <app-auto-adjusting-dropdown [selected]="holdingNameForBreadcrumb"
              [dataForBreadcrumbs]="dealAndHoldingsDataForBreadcrumb" (goToSelected)="goToSelectedHoldings($event)"
              [queryParams]="queryParamsHoldingId" (sendWidth)="getWidth($event)" class="px-3.5 md:px-0"
              [valueProperty]="'id'" [nameProperty]="'name'" [groupProperty]="'subEntities'" [type]="'Holdings'"
              data-cy="holdingsDropdown"></app-auto-adjusting-dropdown>
          </div>
        </div>
        <div class="grid grid-cols-1 mt-4 md:mt-0">
          <div class="flex col-span-2">
            <div class="flex ml-auto">
              <div>
                <img src="{{iconSet['icon.calendar']}}" class="mt-2 mr-1.5" />
              </div>
              <gcm-date-picker [dateOptionList]="asOfDropdownData" [(date)]="selectedAsOfDate"
                (dateChange)="updateAsOfDate($event)" [selectPanelClass]="'as-of-date-panel'" data-cy="asOfDateDropdown"
                class="as-of-date mr-3" [disabled]="isHoldingDataUnavailable && valReportingDate == date"
                [ngClass]="{'error-text' : isHoldingDataUnavailable && valReportingDate}"></gcm-date-picker>
            </div>
          </div>
          <div class="snap-shot mr-3 ml-auto" *ngIf="!isHoldingDataUnavailable">
            <button class="my-0 md:my-2.5 flex border-primary" (click)="getSnapshot();" data-cy="holdingSnapshot"><img
                src="{{iconSet['icon.snapshot']}}" class="mr-2 mt-1" />Snapshot</button>
          </div>
        </div>
        <app-error-card *ngIf="isHoldingDataUnavailable" data-cy="errorCard">
          <h3 class="card-title">Holding data for the selected reporting period <b>{{selectedAsOfDate | dateFormat :
              'MM/dd/yyyy'}}</b> is not available.</h3>
          <div>
            <div>Please return to the prior page or select a new date from the As of Date Dropdown.</div>
            <div>Click
              <a routerLinkActive="active" [routerLink]="breadcrumbs[0]?.route"
                (click)="breadcrumbClick(breadcrumbs[0])"
                [queryParams]="breadcrumbs[0]?.queryParams"
                class="click-here-btn" data-cy="investorDashboardRedirect">
                here
              </a>
              to go back to the Investor dashboard.
            </div>
          </div>
        </app-error-card>
        <div class="card detail-card bg-white">
          <div class="mx-0 mb-2 lg:pl-0 text-5xl detail text-charcoal-grey" *ngIf="isSingleAssetHolding">
            {{dealDetailLabel}}
          </div>
          <div class="row grid grid-cols-1 lg:grid-cols-12">
            <div class="col-span-6"
              [ngClass]="isSingleAssetHolding && (assetSummary.logo || assetSummary.showAssetUrl) ? 'lg:col-span-7' : 'lg:col-span-12'">
              <div class="row grid grid-cols-1 mt-4 md:mt-0 min-h-10">
                <div class="md:flex items-baseline">
                  <div class="product-container__deal-name text-2xl md:text-4xl">
                    {{dealName}}
                  </div>
                  <div *ngIf="summary.investmentType == 'Co-investment/Direct'"
                    class="breadcrumbAsOfDate m-0 mt-1 md:mt-1.5 p-0 md:pl-1.5 flex" data-cy="asOfDate">
                    as of
                    {{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}
                    <div *ngIf="summary.publishValuationType === 'Audit'" data-cy="auditPublished" class="final">
                      --
                      Final
                    </div>
                  </div>
                </div>
              </div>
              <div class="row grid grid-cols-1 pt-2">
                <div class="product-container__holdings-title">
                  <div *ngIf="summary.investmentType != 'Co-investment/Direct'"
                    class="md:flex holding-title text-md md:text-lg" id="holdingName" data-cy="holdingTitle">
                    {{holdingTitle}}
                    <div class="breadcrumbAsOfDate m-0 mt-1 md:mt-1.5 p-0 md:pl-1.5 flex" data-cy="asOfDate">
                      as of
                      {{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}
                      <div *ngIf="summary.publishValuationType === 'Audit'" data-cy="auditPublished" class="final">
                        --
                        Final
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img class="asset-image" [src]="assetClassImage[1]" [matTooltip]="assetClassImage[0]"
                matTooltipClass="blue-tooltip" matTooltipPosition="right" data-cy="assetClassImage">
            </div>
            <div class="col-span-6 lg:col-span-5" *ngIf="isSingleAssetHolding">
              <div class="mx-0 lg:pl-2">
                <div class="lg:px-0">
                  <div *ngIf="assetSummary.logo" class="logo">
                    <img src="{{assetSummary.logo}}" data-cy="assetLogo">
                  </div>
                  <span *ngIf="assetSummary.showAssetUrl" class="text-md md:text-lg my-auto text-primary underline">
                    <a href="{{assetSummary.assetURL}}" target="_blank"
                      (click)="activityTracking.navigateToAssetUrl('single asset holding dashboard', assetMasterId, assets[0].name, assetSummary.assetURL)">
                      {{assetSummary.assetURL}}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <app-holding-dashboard-summary [iconSet]="iconSet" [cfmanagerLogo]="cfmanagerLogo"
            [holdingSummaryText]="holdingSummary" [holdingTitle]="holdingTitle"
            [holdingSummary]="summary"></app-holding-dashboard-summary>
          <!-- Hide the charts and asset table when we have single asset holdings -->
          <ng-container *ngIf="!isSingleAssetHolding && !summary.dropdownPortfolioSeriesMasterId">
            <div class="row mt-4 widget-row grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0">
              <div class="pl-0 md:pr-[30px]" tourAnchor="holding.dashboard.sector">
                <app-sectors-widget [widgets]="widgets" [currency]="currency"></app-sectors-widget>
              </div>
              <div class="pl-0" tourAnchor="holding.dashboard.geography">
                <app-geography-widget [widgets]="widgets" [currency]="currency"></app-geography-widget>
              </div>
            </div>
            <app-largest-position-widget [widgets]="widgets" [currency]="currency"></app-largest-position-widget>
            <app-assets-detail-table [portfolioName]="portfolioName" [seriesName]="seriesName"
              [asOfDate]="selectedAsOfDate" [holdingName]="holdingTitle" [assets]="assets"
              [currency]="currency"></app-assets-detail-table>
          </ng-container>
          <ng-container *ngIf="summary.dropdownPortfolioSeriesMasterId">
            <div class="card detail-card mt-5 mb-0">
              <div class="text-center font-bold">
                {{dealName}} is a GCM Grosvenor Specialized Fund.
                <!-- NOTE: For commingled experience we are removing this for now, will add back in later.
                To see complete details on the Specialized Fund, click 
                <a routerLinkActive="active"
                  routerLink="/investor/{{queryParamsInvestorId}}/series/{{summary.dropdownPortfolioSeriesMasterId}}"
                  [queryParams]="{reportingdate: valReportingDate}" class="commingled-btn">
                  HERE</a>. -->
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Show the asset summary when we have single asset holdings -->
        <ng-container *ngIf="isSingleAssetHolding">
          <div class="card detail-card bg-white">
            <div class="mx-0 mb-4 lg:pl-0 text-5xl detail text-charcoal-grey">
              {{assetDetailLabel}}
            </div>
            <app-asset-summary [assetType]="assets[0]?.assetType" [assetData]="assets[0]" [title]="assets[0].name"
              [summary]="assetSummary.summary" [logo]="assetSummary.logo" [showingOnHoldingDashboard]="true"
              [slidingImgs]="assetSummary.slidingImgs"></app-asset-summary>
            <!-- Will Add financial Summary widget here once the data is available for the same -->
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</div>
